import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PLANS, VALIDATIONS } from '../API/tempartner';
import { QueryKeys } from '../constants/queryKeys.const';
import { ROUTES } from '../constants/routes.const';
import { STORAGE } from '../constants/storage.const';
import { DriverContext, RescheduleAnswer } from '../contexts/DriverContext';
import { IError } from '../interfaces/error.interface';
import { IPLanResponse, PlanShapes } from '../interfaces/plan.interface';
import { IUser } from '../interfaces/userData.interface';
import { MessageTypes } from '../pages/InfoView/messages.const';
import { errorService, isGivenCustomError } from '../services/error.service';
import { getData, postData } from '../services/request.service';
import { EmptyObject } from '../utils/interface';
import { storeItem } from '../utils/localStorage';
import { normalizePlan } from '../utils/plan';
import { messageRoute } from '../utils/route';
import { ERROR_DETAILS } from '../utils/serverErrorHandler';
import { loggedUser, pendingLogin } from '../utils/user';
import { useGuardedQuery } from './useGuardedQuery';

type UserHookReturn = {
  user: IUser | undefined;
  plan: PlanShapes | undefined;
  isLoading: boolean;
};

const UBS_CONFLICT_RETRY_COUNT = 1;
const parseReschedule = {
  Unanswered: 'unanswered',
  Rescheduled: 'rescheduled',
  Unsheculed: 'unscheduled',
  Unscheduled: 'unscheduled',
};

/**
 * loads user data
 * loads user plan if logged in
 * redirects to login | signup flow if user is not logged in
 * @returns user, data, isLoading
 */
export const useUser = (): UserHookReturn => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    publicId,
    region,
    setFitForBpv,
    setEmail,
    setEmailBpv,
    setUserHasCreditCard,
    setUserState,
    setRefreshToken,
    setUserTripInfo,
    setRescheduleAnswer,
  } = useContext(DriverContext);

  const userBody = { region, public_id: publicId };

  const onError = (): void => {
    const errorRoute = messageRoute(MessageTypes.ERROR_BOOTSTRAP);

    if (
      location.pathname !== errorRoute &&
      location.pathname !== ROUTES.SIGNUP_DOCUMENT &&
      userBody.public_id &&
      userBody.region
    ) {
      navigate(errorRoute);
    }
  };

  const { isLoading: isLoadingUserData, data: user } = useGuardedQuery<IUser>(
    [QueryKeys.userData],
    async () => {
      try {
        const response = await postData<IUser>(VALIDATIONS.USER, userBody);
        storeItem(STORAGE.USER_DATA, response);
        if (response.user_contact.tembici_email) {
          setEmail(response.user_contact.tembici_email);
        }
        if (response.user_contact.bpv_email) {
          setEmailBpv(response.user_contact.bpv_email);
        }
        if (response.bpv_state) {
          setUserState(response.bpv_state);
        }
        if (response.refresh_token && typeof response.refresh_token === 'string') {
          setRefreshToken(response.refresh_token);
        }
        if (response.trip) {
          setUserTripInfo(response.trip);
        }
        setFitForBpv(response.fit_for_bpv);
        setUserHasCreditCard(response.has_card);
        const rescheduleAnswer = parseReschedule[response.trip?.rescheduled];
        if (rescheduleAnswer) {
          setRescheduleAnswer(rescheduleAnswer as RescheduleAnswer);
        }

        return response;
      } catch (error) {
        throw errorService(error as IError);
      }
    },
    {
      enabled: !!userBody.public_id && !!userBody.region,
      onSuccess: (data): void => {
        if (location.pathname !== ROUTES.MAIORES_DETALHES && pendingLogin(data?.user_status)) {
          navigate(ROUTES.MAIORES_DETALHES);
        }
      },
      onError,
    },
  );

  const planParams = [region];

  const { isLoading: isLoadingUserPlan, data: plan } = useGuardedQuery<PlanShapes>(
    [QueryKeys.userPlan],
    async () => {
      try {
        const response = await getData<IPLanResponse | EmptyObject>(PLANS.SIGNED, planParams);
        if (!('id' in response)) return response;
        return normalizePlan(response);
      } catch (error) {
        const err = errorService(error as IError);
        if (isGivenCustomError(err, ERROR_DETAILS.USER_NEEDS_TO_REVIEW_PLAN)) return null;
        throw err;
      }
    },
    {
      enabled: loggedUser(user?.user_status),
      retry: UBS_CONFLICT_RETRY_COUNT,
      onError,
    },
  );

  useEffect(() => {
    setIsLoading(isLoadingUserData || isLoadingUserPlan);
  }, [isLoadingUserData, isLoadingUserPlan]);

  return { user, plan, isLoading };
};
