/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ICustomError, ICustomErrorData } from '../interfaces/error.interface';

export interface Error {
  error: string;
  type: string;
  message: string;
}

export enum ErrorTypes {
  SERVER_ERROR = 'SERVER_ERROR',
  CLIENT_NOT_FOUND = 'CLIENT_NOT_FOUND',
  CANT_LOAD_USER = 'CANT_LOAD_USER',
  CANT_LOAD_USER_PLANS = 'CANT_LOAD_USER_PLANS',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_HAS_OPEN_TRIP = 'USER_HAS_OPEN_TRIP',
  CANT_GENERATE_QRCODE = 'CANT_GENERATE_QRCODE',
  USER_DONT_HAVE_ANY_CREDIT_CARDS = 'USER_DONT_HAVE_ANY_CREDIT_CARDS',
  CANT_LOAD_USER_INFO = 'CANT_LOAD_USER_INFO',
  CANT_SET_USER_INFO = 'CANT_SET_USER_INFO',
  COMMUNICATION_ERROR_AT_TEMBICI = 'COMMUNICATION_ERROR_AT_TEMBICI',
  LOCATION_IS_NOT_BOUNDED_WITH_CLIENT = 'LOCATION_IS_NOT_BOUNDED_WITH_CLIENT',
  MERCHANT_NOT_FOUND = 'MERCHANT_NOT_FOUND',
  INVALID_CREDIT_CARD_FOR_ADYEN = 'INVALID_CREDIT_CARD_FOR_ADYEN',
  INVALID_CREDIT_CARD_FOR_TEMBICI = 'INVALID_CREDIT_CARD_FOR_TEMBICI',
  PLAN_NOT_PURCHASED = 'PLAN_NOT_PURCHASED',
  PLAN_NOT_FOUND = 'PLAN_NOT_FOUND',
  USER_NEEDS_TO_REVIEW_PLAN = 'USER_NEEDS_TO_REVIEW_PLAN',
  INVALID_UUID = 'INVALID_UUID',
  INVALID_COUPON = 'INVALID_COUPON',
  VOUCHER_INVALID_FOR_THIS_PLAN = 'VOUCHER_INVALID_FOR_THIS_PLAN',
  INVALID_SIGNIN = 'INVALID_SIGNIN',
  CANT_SIGNUP = 'CANT_SIGNUP',
  CANT_LOAD_BIKES_AVAILABILITY = 'CANT_LOAD_BIKES_AVAILABILITY',
  INVALID_LOGIN_OR_PASSWORD = 'INVALID_LOGIN_OR_PASSWORD',
  UBS_CONFLICT = 'UBS_CONFLICT',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  DOCUMENT_INVALID = 'DOCUMENT_INVALID',
  DOCUMENT_ALREADY_EXISTS = 'DOCUMENT_ALREADY_EXISTS',
  DOCUMENT_IN_BLACKLIST = 'DOCUMENT_IN_BLACKLIST',
  USER_NOT_CREATED_ON_TEMUSER = 'USER_NOT_CREATED_ON_TEMUSER',
  DOCUMENT_INVALID_NAME = 'DOCUMENT_INVALID_NAME',
  DOCUMENT_INVALID_BIRTHDAY = 'DOCUMENT_INVALID_BIRTHDAY',
  DOCUMENT_INVALID_AGE = 'DOCUMENT_INVALID_AGE',
  DAILY_LIMIT_EXCEEDED = 'DAILY_LIMIT_EXCEEDED',
  INVALID_CODE = 'INVALID_CODE',
  CODE_EXPIRED = 'CODE_EXPIRED',
  USER_HAS_NO_CURRENT_PLAN = 'USER_HAS_NO_CURRENT_PLAN',
  USER_PLAN_SUSPENDED = 'USER_PLAN_SUSPENDED',
  DAILY_TRIPS_EXCEEDED = 'DAILY_TRIPS_EXCEEDED',
  PASSWORD_WEAKNESS = 'PASSWORD_WEAKNESS',
  USER_HAS_NO_OPEN_TRIP = 'USER_HAS_NO_OPEN_TRIP',
  CANT_LOAD_USER_OPEN_TRIP = 'CANT_LOAD_USER_OPEN_TRIP',
  CANT_TOGGLE_PATINETE_STATUS = 'CANT_TOGGLE_PATINETE_STATUS',
  CIRCUIT_BREAKER_OPENED = 'CIRCUIT_BREAKER_OPENED',
  UNKNOWN_CODE = 'UNKNOWN_CODE',
  BIKE_NOT_FROM_SUPPORT_BUFFER = 'BIKE_NOT_FROM_SUPPORT_BUFFER',
  BIKE_OUTSIDE_SUPPORT_BUFFER_RANGE = 'BIKE_OUTSIDE_SUPPORT_BUFFER_RANGE',
  BIKE_ALREADY_IN_TRIP = 'BIKE_ALREADY_IN_TRIP',
  USER_ALREADY_IN_TRIP = 'USER_ALREADY_IN_TRIP',
  CANT_CANCEL_AUTO_PLAN_MIGRATION = 'CANT_CANCEL_AUTO_PLAN_MIGRATION',
  USER_HAS_A_PENALTY_AND_IT_S_NOT_ALLOWED = 'USER_HAS_A_PENALTY_AND_IT_S_NOT_ALLOWED',
  TOO_MANY_MSGS_TODAY = 'TOO_MANY_MSGS_TODAY',
  USER_IS_BLOCKED_AND_IT_S_NOT_ALLOWED = 'USER_IS_BLOCKED_AND_IT_S_NOT_ALLOWED',
  BPV_PLAN_ACTIVE = 'BPV_PLAN_ACTIVE',
  INVALID_NUMBER = 'INVALID_NUMBER',
  SMS_EXCEED_RETRIES = 'SMS_EXCEED_RETRIES',
  INVALID_SMS_CODE = 'INVALID_SMS_CODE',
  SOCIAL_LOGIN_CONFLICT = 'SOCIAL_LOGIN_CONFLICT',
}
type ErrorDataCollection = {
  [E in ErrorTypes]: ICustomErrorData;
};
export const ERROR_DETAILS: ErrorDataCollection = {
  SERVER_ERROR: {
    ID: 0,
    TYPE: 'Default',
    MESSAGE: 'Ocorreu um erro em nossos servidores e esta ação não pode ser concluida no momento.',
  },
  CLIENT_NOT_FOUND: {
    ID: 1,
    TYPE: 'client_not_found',
    MESSAGE: 'Cliente não encontrado',
  },
  CANT_LOAD_USER: {
    ID: 2,
    TYPE: 'cant_load_user',
    MESSAGE: 'Não foi possível carregar o usuário',
  },
  CANT_LOAD_USER_PLANS: {
    ID: 3,
    TYPE: 'cant_load_user_plans',
    MESSAGE: 'Não foi possivel carregar o plano',
  },
  USER_NOT_FOUND: {
    ID: 4,
    TYPE: 'user_not_found',
    MESSAGE: 'Usuário não encontrado',
  },
  USER_HAS_OPEN_TRIP: {
    ID: 5,
    TYPE: 'user_has_open_trip',
    MESSAGE: 'Você possui uma viagem em aberto',
  },
  CANT_GENERATE_QRCODE: {
    ID: 6,
    TYPE: 'cant_generate_qrcode',
    MESSAGE: 'Erro ao gerar um código',
  },
  USER_DONT_HAVE_ANY_CREDIT_CARDS: {
    ID: 8,
    TYPE: 'user_dont_have_any_credit_cards',
    MESSAGE: 'Você não tem nenhum cartão de credito cadastrado',
  },
  CANT_LOAD_USER_INFO: {
    ID: 9,
    TYPE: 'cant_load_user_info',
    MESSAGE: 'Não foi possível carregar as informações do usuário',
  },
  CANT_SET_USER_INFO: {
    ID: 10,
    TYPE: 'cant_set_user_info',
    MESSAGE: 'Não foi possível definir as informações do usuário',
  },
  COMMUNICATION_ERROR_AT_TEMBICI: {
    ID: 11,
    TYPE: 'communication_error_at_tembici',
    MESSAGE: 'Ocorreu um erro em nossos servidores e esta ação não pode ser concluida no momento.',
  },
  LOCATION_IS_NOT_BOUNDED_WITH_CLIENT: {
    ID: 12,
    TYPE: 'location_is_not_bounded_with_client',
    MESSAGE: 'Selecione uma estação válida.',
  },
  MERCHANT_NOT_FOUND: {
    ID: 13,
    TYPE: 'merchant_not_found',
    MESSAGE: 'Fornecedor não encontrado',
  },
  INVALID_CREDIT_CARD_FOR_ADYEN: {
    ID: 14,
    TYPE: 'invalid_credit_card_for_adyen',
    MESSAGE: 'Cartão de crédito inválido',
  },
  INVALID_CREDIT_CARD_FOR_TEMBICI: {
    ID: 15,
    TYPE: 'invalid_credit_card_for_tembici',
    MESSAGE: 'Cartão de crédito inválido',
  },
  PLAN_NOT_PURCHASED: {
    ID: 16,
    TYPE: 'plan_not_purchased',
    MESSAGE: 'Não foi possivel realizar a compra',
  },
  USER_NEEDS_TO_REVIEW_PLAN: {
    ID: 17,
    TYPE: 'user_needs_to_review_plan',
    MESSAGE: 'Você já possui um plano ativo',
  },
  INVALID_UUID: {
    ID: 18,
    TYPE: 'invalid_uuid',
    MESSAGE: 'Identificação inválida',
  },
  VOUCHER_INVALID_FOR_THIS_PLAN: {
    ID: 19,
    TYPE: 'voucher_invalid_for_this_plan',
    MESSAGE: 'Voucher inválido para este plano',
  },
  INVALID_SIGNIN: {
    ID: 20,
    TYPE: 'invalid_signin',
    MESSAGE: 'Senha incorreta. Tente novamente ou recupere sua senha',
  },
  CANT_SIGNUP: {
    ID: 21,
    TYPE: 'cant_signup',
    MESSAGE: 'Não foi possível realizar cadastro, entre em contato com o suporte',
  },
  CANT_LOAD_BIKES_AVAILABILITY: {
    ID: 22,
    TYPE: 'cant_load_bikes_availability',
    MESSAGE: 'Não foi possível carregar informações de Pontos de Apoio',
  },
  INVALID_LOGIN_OR_PASSWORD: {
    ID: 23,
    TYPE: 'invalid_login_or_password',
    MESSAGE: 'Senha incorreta. Tente novamente ou recupere sua senha',
  },
  UBS_CONFLICT: {
    ID: 24,
    TYPE: 'ubs_conflict',
    MESSAGE:
      'A resposta do nosso servidor está um pouco lenta. Aguarde alguns minutos e tente novamente.',
  },
  EMAIL_NOT_FOUND: {
    ID: 25,
    TYPE: 'email_not_found',
    MESSAGE:
      'O e-mail inserido não pode ser verificado. Para continuar, insira outro endereço válido.',
  },
  EMAIL_ALREADY_EXISTS: {
    ID: 26,
    TYPE: 'email_already_exists',
    MESSAGE: 'Parece que já existe uma conta atrelada a este e-mail.',
  },
  DOCUMENT_INVALID: {
    ID: 27,
    TYPE: 'document_invalid',
    MESSAGE:
      'Tivemos um problema para processar sua solicitação. Entre em contato com a gente pela Central de Ajuda para resolvermos por lá.',
  },
  DOCUMENT_ALREADY_EXISTS: {
    ID: 28,
    TYPE: 'document_already_exists',
    MESSAGE: 'Parece que já existe uma conta atrelada a este documento.',
  },
  DOCUMENT_IN_BLACKLIST: {
    ID: 29,
    TYPE: 'document_in_blacklist',
    MESSAGE:
      'Tivemos um problema para processar sua solicitação. Entre em contato com a gente pela Central de Ajuda para resolvermos por lá.',
  },
  USER_NOT_CREATED_ON_TEMUSER: {
    ID: 30,
    TYPE: 'user_not_created_on_temuser',
    MESSAGE:
      'Tivemos um problema para processar sua solicitação. Entre em contato com a gente pela Central de Ajuda para resolvermos por lá.',
  },
  DOCUMENT_INVALID_NAME: {
    ID: 31,
    TYPE: 'document_invalid_name',
    MESSAGE: 'Digite um nome válido',
  },
  DOCUMENT_INVALID_BIRTHDAY: {
    ID: 32,
    TYPE: 'document_invalid_birthday',
    MESSAGE: 'Digite uma data de nascimento válida',
  },
  DOCUMENT_INVALID_AGE: {
    ID: 33,
    TYPE: 'document_invalid_age',
    MESSAGE: 'Digite uma data de nascimento válida',
  },
  DAILY_LIMIT_EXCEEDED: {
    ID: 34,
    TYPE: 'daily_limit_exceeded',
    MESSAGE: 'Número de tentativas expirado',
  },
  INVALID_CODE: {
    ID: 35,
    TYPE: 'invalid_code',
    MESSAGE: 'Código inválido',
  },
  CODE_EXPIRED: {
    ID: 36,
    TYPE: 'code_expired',
    MESSAGE: 'Código expirado, solicite um novo código',
  },
  USER_HAS_NO_CURRENT_PLAN: {
    ID: 37,
    TYPE: 'user_has_no_current_plan',
    MESSAGE: 'Usuário sem plano atual',
  },
  USER_PLAN_SUSPENDED: {
    ID: 38,
    TYPE: 'user_plan_suspended',
    MESSAGE: 'Seu plano está suspenso. Entenda mais sobre a situação falando com o suporte.',
  },
  DAILY_TRIPS_EXCEEDED: {
    ID: 39,
    TYPE: 'DAILY_TRIPS_EXCEEDED',
    MESSAGE: 'Viagens diárias excedidas. Procure o suporte em caso de dúvidas.',
  },
  PASSWORD_WEAKNESS: {
    ID: 40,
    TYPE: 'password_weakness',
    MESSAGE: 'Senha Fraca',
  },
  USER_HAS_NO_OPEN_TRIP: {
    ID: 41,
    TYPE: 'user_has_no_open_trip',
    MESSAGE: 'Usuário não possui viagem aberta',
  },
  CANT_LOAD_USER_OPEN_TRIP: {
    ID: 42,
    TYPE: 'cant_load_user_open_trip',
    MESSAGE: 'Não é possível carregar a viagem do usuário',
  },
  CANT_TOGGLE_PATINETE_STATUS: {
    ID: 43,
    TYPE: 'cant_toggle_patinete_status',
    MESSAGE: 'Não é possível alterar o status do patinete',
  },
  CIRCUIT_BREAKER_OPENED: {
    ID: 44,
    TYPE: 'circuit_breaker_opened',
    MESSAGE: 'Ocorreu um erro em nossos servidores. Aguarde alguns minutos e tente novamente.',
  },
  PLAN_NOT_FOUND: {
    ID: 45,
    TYPE: 'plan_not_found',
    MESSAGE: 'Não foi possivel encontrar o plano',
  },
  INVALID_COUPON: {
    ID: 46,
    TYPE: 'invalid_coupon',
    MESSAGE: 'Cupom inválido',
  },
  UNKNOWN_CODE: {
    ID: 47,
    TYPE: 'unknown_code',
    MESSAGE: 'Código não encontrado',
  },
  BIKE_NOT_FROM_SUPPORT_BUFFER: {
    ID: 48,
    TYPE: 'bike_not_from_support_buffer',
    MESSAGE: 'Código não encontrado',
  },
  BIKE_OUTSIDE_SUPPORT_BUFFER_RANGE: {
    ID: 49,
    TYPE: 'bike_outside_support_buffer_range',
    MESSAGE: 'Bike fora do ponto de apoio',
  },
  BIKE_ALREADY_IN_TRIP: {
    ID: 50,
    TYPE: 'bike_already_in_trip',
    MESSAGE: 'Bike está em viagem',
  },
  USER_ALREADY_IN_TRIP: {
    ID: 51,
    TYPE: 'user_already_in_trip',
    MESSAGE: 'Usuário está em viagem',
  },
  CANT_CANCEL_AUTO_PLAN_MIGRATION: {
    ID: 52,
    TYPE: 'cant_cancel_auto_plan_migration',
    MESSAGE: 'Não foi possível cancelar a auto migração',
  },
  USER_HAS_A_PENALTY_AND_IT_S_NOT_ALLOWED: {
    ID: 54,
    TYPE: 'user_has_a_penalty_and_it_s_not_allowed',
    MESSAGE: 'Usuário possui penalidade e não tem permissão de acesso',
  },
  TOO_MANY_MSGS_TODAY: {
    ID: 55,
    TYPE: 'too_many_msgs_today',
    MESSAGE: 'Você pode enviar o link do curso apenas 2 vezes por dia',
  },
  USER_IS_BLOCKED_AND_IT_S_NOT_ALLOWED: {
    ID: 56,
    TYPE: 'user_is_blocked_and_it_s_not_allowed',
    MESSAGE: 'Usuário está bloqueado e não tem permissão de acesso',
  },
  BPV_PLAN_ACTIVE: {
    ID: 57,
    TYPE: 'bpv_plan_active',
    MESSAGE: 'Usuário já possui plano ativo no Bike pra Você',
  },
  INVALID_NUMBER: {
    ID: 58,
    TYPE: 'invalid_number',
    MESSAGE: 'Número inválido',
  },
  SMS_EXCEED_RETRIES: {
    ID: 59,
    TYPE: 'sms_exceed_retries',
    MESSAGE: 'Você atingiu o limite de sms',
  },
  INVALID_SMS_CODE: {
    ID: 60,
    TYPE: 'invalid_sms_code',
    MESSAGE: 'Código inválido',
  },
  SOCIAL_LOGIN_CONFLICT: {
    ID: 61,
    TYPE: 'social_login_conflict',
    MESSAGE: 'Ops! Parece que ocorreu um erro. Tente recuperar sua senha antes de continuar.',
  },
} as const;

export const serverErrorHandler = (error: ICustomError): ICustomErrorData => {
  const { detail, status_code } = error;
  if (status_code !== 403) {
    const errorPattern = /<Errors\.([a-z_]+):/;
    const [, match = ''] = errorPattern.exec(detail) ?? [];
    const errorType = match.toUpperCase();
    return ERROR_DETAILS[errorType as keyof typeof ERROR_DETAILS] || ERROR_DETAILS.SERVER_ERROR;
  }
  const errorPattern = detail.toLowerCase().replace(/['\s]/g, '_').replace('.', '');
  const errorType = errorPattern.toUpperCase();
  return ERROR_DETAILS[errorType as keyof typeof ERROR_DETAILS] || ERROR_DETAILS.SERVER_ERROR;
};

export const errorMapper = (error: any): Error => {
  if (error?.status_code && error?.message) {
    return {
      error: String(error.status_code),
      message: String(error.message),
      type: String(error.status_code),
    };
  }

  if (error?.ID && error?.TYPE && error?.MESSAGE) {
    return {
      error: String(error.ID),
      message: String(error.MESSAGE),
      type: String(error.TYPE),
    };
  }

  if (error?.STATUS && error?.ERRORS?.length > 0) {
    return {
      error: String(error?.STATUS),
      message: error?.ERRORS[0],
      type: String(error?.STATUS),
    };
  }

  return {
    error: 'unknown',
    message: 'Something went wrong',
    type: 'unknown',
  };
};
