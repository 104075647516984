export const PLANS = {
  LIST: `api/v3/payments/system/plans/{0}/`,
  DETAIL: `api/v3/payments/plan/detail/`,
  AUTH_LIST: `api/v3/payments/plans/?region={0}&payment_type={1}`,
  SIGNED: `api/v3/payments/plan/?region={0}`,
  BUY_PLAN: `api/v3/payments/plan/buy/{0}/`,
  MIGRATE_PLAN: `api/v3/payments/plans/migrate/`,
  GET_PLANS_BPV: `api/v1/bpv-plans/plans/?region={0}`,
  BUY_PLAN_BPV: `api/v3/payments/bpv-plan/buy/`,
};

export const CREDIT_CARD = {
  GET: `api/v2/payments/credit-card/`,
  SIGNUP: `api/v3/payments/credit-card/tokenize/?region={0}`,
};

export const SIGNUP = {
  NEW: `api/v1/accounts/signup/`,
  ADDRESS: `api/v2/accounts/address/?region={0}`,
  BPV_POST: `api/v1/bpv/signup/`,
};

export const REWARDS = {
  OPEN: `api/v2/benefits/open/?region={0}`,
  EXPIRED: `api/v2/benefits/expired/?region={0}`,
  WITHDRAWN: `api/v2/benefits/withdrawals/?region={0}&page_number={1}&count={2}`,
};

export const VALIDATIONS = {
  USER: `api/v3/accounts/user/validation/`,
  // TODO: We should have only one endpoint
  USER_BPV: 'api/v1/bpv/user-info?public_id={0}&region={1}',
  USER_STATE: 'api/v1/bpv/user-state/',
};

export const ACCESS = {
  LOGIN: {
    EMAIL: `api/v1/accounts/signin/`,
    DOCUMENT: `api/v1/accounts/signin/document/`,
  },
  RECOVERY_PASSWORD: `api/v1/accounts/recovery-password/`,
  TOKEN: {
    GENERATE: `api/v2/accounts/generate-sms-code/`,
    VALIDATE: `api/v1/accounts/validate-sms-code/`,
  },
  CHANGE_PASSWORD: `api/v2/accounts/change-password/`,
};

export const CAMPAIGNS = {
  BANNER: `api/v2/campaign/{0}/?public_id={1}`,
};

export const QRCODE = {
  GENERATE: `api/v3/system/qrcode/`,
  CHECK_IN: `api/v1/bpv/qrcode/?region={0}`,
};

export const STATION = {
  AVAILABILITY: `api/v2/system/bikes-availability/?region={0}`,
};

export const BIKECODE = {
  GET: `api/v3/system/support-buffer/bike/?region={0}&code={1}`,
};
export const UNLOCKBIKE = {
  POST: `api/v3/system/support-buffer/bike/unlock/?region={0}`,
};
export const LOCKBIKE = {
  POST: `api/v3/system/support-buffer/bike/lock/?region={0}`,
};

export const ACTIONBUTTONS = {
  GET: `api/v1/content/hub/?region={0}`,
};

export const PENALTY = {
  PATCH: `api/v1/penalty/cancel-auto-migration/`,
  FUTURE_PLAN: {
    GET: `api/v1/penalty/plan/?region={0}`,
  },
};

export const SEND_WPP = {
  POST: 'api/v3/accounts/send-wpp/',
};

export const PRE_SIGNUP = {
  GET: 'api/v1/bpv/pre-signup/{0}/',
};

export const SIGNUP_DOCUMENT = {
  GET: 'api/v1/bpv/validate-token/{0}/',
};

export const SIGNUP_DOCUMENT_V2 = {
  POST: 'api/v2/bpv/documents/',
};

export const SEND_SMS = {
  POST: 'api/v1/bpv/send-sms/',
};

export const VALIDATE_SMS = {
  POST: 'api/v1/bpv/validate-sms/',
};

export const SEND_EMAIL = {
  POST: 'api/v1/bpv/send-email/',
};

export const SCHEDULE = {
  PICKUP_PLACES: {
    GET: 'api/v2/bpv-bookings/availability/?region={0}&start_date={1}&end_date={2}',
  },
  DETAIL: {
    POST: `api/v2/bpv-bookings/bookings/`,
    GET: `api/v2/bpv-bookings/bookings/`,
  },
  RESCHEDULE: {
    POST: `api/v2/bpv-bookings/reschedule/`,
  },
};
